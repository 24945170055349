<template>
  <v-card color="#fff" class="grey--text">
    <v-card-title >
      <v-row>
        <v-col cols="3">
          <b color="black">Ouvidoria</b>
    
        </v-col>
        <v-col class="text-right">
         
          <v-btn 
            v-for="(p,i) in permissoes.papel"
            :key="`p-${i}`"
            outlined
            class="mx-1 grey--text"
            @click="escolhePapel(p.id)"
          >{{p.text}}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="subtitle-1 ma-2 text-center">
      Este serviço é altamente confidencial e somente a coordenação de gestão de pessoas terá acesso às suas informações reportadas.
    </v-card-subtitle>
  
    <v-container v-if="colaborador">
      <v-row>
        <v-col>
          <Add />
        </v-col>
        <v-col>
          <Lista />
        </v-col>
      </v-row>
    </v-container>
    
    <v-container v-if="avaliador" class="red--text text-center">
      <p>Avaliador - Você não tem permissões necessárias para visualizar este módulo.</p><br> 
      <h1>:\</h1>
    </v-container>
    <v-container v-if="administrador">
      <div v-if="verificarSeEOuvidoria()">
        <Administrador />
      </div>
      <div v-else class="red--text text-center">
        <p>Administrador - Você não tem permissões necessárias para visualizar este módulo.</p><br> 
        <h1>:\</h1>
      </div>
    </v-container> 
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:'Ouvidoria',
  data:()=>({
    avaliador:false,
    colaborador:true,
    administrador:false
  }),
  components:{
    Add:()=>import('./Add.vue'),
    Lista:()=>import('./Lista.vue'),
    Administrador:()=>import('./Administrador.vue'),
  },
  computed:{
    ...mapGetters(['permissoes', 'usuario'])
  },
  methods:{
    verificarSeEOuvidoria(){
      return this.permissoes.ouvidoria;
    },
    escolhePapel(val){
      if(val==1){
        this.colaborador = true
        this.avaliador = false
        this.administrador = false  
      }else if(val==2){
        this.colaborador = false
        this.avaliador = true
        this.administrador = false
      }else if( val==3){
        this.avaliador = false
        this.colaborador = false
        this.administrador = true
      }
    }
  },
  mounted(){
    this.$store.dispatch('btnLoading',false)
  }
}
</script>
